.App {
  width: 100vw;
  height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
}

.AppHeader {
  height: 10vh;
  min-height: 65px;
  width: 100vw;
  background-color: #e8f8ff;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  position:relative;
  z-index:999;
}

.AppLogo {
  height: 100%;
  min-height: 65px;
}

.AppBody {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100vw;
  height: 85vh;
}

.AppBodyCont {
  display: flex;
  flex-direction: column;
  width: 100vw;
  height: 85vh;
}

.AppBodyEsp {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100vw;
  max-width: 180vh;
  margin: auto;
}

.backgroundIntroduction {
  background-image: url("../assets/background.png");
  background-size: cover;
}

.backgroundUs {
  background-image: url("../assets/clouds.png");
  background-size: cover;
  height: 90vh;
  width: 100vw;
}

.headerButtons {
  display: flex;
  flex-direction: row;
  height: 50%;
  float: right;
}

.button {
  background-color: transparent;
  -webkit-box-shadow: 2px 0px 0px 0px #325eab55 inset !important;
  box-shadow: 2px 0px 0px 0px #325eab55 inset !important;
  color: #000000;
}

.dropdownMenu {
  background-color: blue;
  -webkit-box-shadow: 2px 0px 0px 0px #325eab55 inset !important;
  box-shadow: 2px 0px 0px 0px #325eab55 inset !important;
  color: #000000;
}

.buttonSelected {
  background-color: blue;
  -webkit-box-shadow: 2px 0px 0px 0px #325eab55 inset !important;
  box-shadow: 2px 0px 0px 0px #325eab55 inset !important;
  color: #ffffff;
}

.textImage {
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 50px;
  width: 88%;
}

.textImageMobile {
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 95%;
}

.text {
  display: flex;
  flex-direction: column;
  text-align: justify;
  padding-right: 25px;
  padding-left: 25px;
  padding-bottom: 50px;
  font-size: 20px;
  overflow: auto;
}

.textCV {
  width: 100%;
  display: flex;
  flex-direction: column;
  text-align: left;
  padding-right: 25px;
  padding-left: 25px;
  padding-bottom: 50px;
}

.image {
  width: 25%;
  /* padding-right: 25px;
  padding-left: 25px; */
  object-fit: contain;
  height: 100px;
  width: 100px;
  margin: auto
}

.portrait {
  width: 25%;
  padding-right: 38px;
  padding-left: 37px;
  object-fit: contain;
  margin-bottom: auto;
}

.visorImagen {
  width: 80%;
  max-height: 85%;
  background-color: rgb(250, 250, 250);
  margin: auto;
  position: relative;
  box-shadow: 0px 0px 12px #000000ff;
  overflow: hidden;
  object-fit: cover;
}

.bloqueEspecialidad {
  background-color: #ffffff; 
  width: 100vw; 
  max-width: 180vh; 
  margin: auto;
}

.textoEspecialidad {
  height: 90vh; 
  width: 50%; 
  padding: 2vw 4vw;
  display: flex;
  flex-direction: column;
  font-family: sans-serif !important;
  text-align: justify;
  font-size: 1.7vh;
  overflow: auto;
}

.textoEspecialidadMobile {
  
  width: 100%; 
  padding: 2vh 4vh;
  display: flex;
  flex-direction: column;
  font-family: sans-serif !important;
  text-align: justify;
  font-size: 1.8vh;
  overflow: auto;
}

.carousel {
  width: 100vw;
  height: 30vw;
  margin-left: auto;
  margin-right: auto;
  align-content: center;
  box-shadow: 0px 0px 12px #000000ff;
}

.carouselUs {
  width: 80vw;
  height: 90vh;
  margin-left: auto;
  margin-right: auto;
  align-content: center;
  display: flex;
  flex-direction: row;
}

.title {
  font-size: 30px;
  font-family: sans-serif !important;
  font-weight: bolder;
  color: #325eab;
  margin-bottom: 0 !important;
  margin-left: 20px;
}

.titleMobile {
  font-size: 20px;
  font-family: sans-serif !important;
  font-weight: bolder;
  color: #325eab;
  margin-bottom: 0 !important;
  margin-left: 10px;
}

.subTitle {
  font-size: 20px;
  font-family: sans-serif !important;
  font-weight: bolder;
  color: #4268ab;
  margin-bottom: 0 !important;
  margin-left: 20px;
}

.personCardTitle {
  width: 80%;
  font-size: 20px;
  font-weight: bolder;
  color: #325eab;
  margin-bottom: 0 !important;
  margin-left: 10%;
  margin-right: 10%;
}

.titleRight {
  font-size: 30px;
  font-weight: bolder;
  color: #325eab;
  margin-bottom: 0 !important;
  margin-right: 20px;
  text-align: right;
}

@keyframes bannerHover {
  from {filter: brightness(70%);}
  to {filter: brightness(40%);}
}

@keyframes bannerUnHover {
  from {filter: brightness(40%);}
  to {filter: brightness(70%);}
}

.imageBanner {
  align-self: center;
  width: 100%;
  min-height: 30vw;
  animation: bannerUnHover 0.5s;
  animation-fill-mode: forwards;
}

.imageBanner:hover {
  align-self: center;
  width: 100%;
  min-height: 30vw;
  margin-top: auto;
  margin-bottom: auto;
  animation: bannerHover 0.5s;
  animation-fill-mode: forwards;
}

@keyframes bannerHoverText {
  from {
    transform: scale(1);
    margin-left: 10vw;
  }
  to {
    transform: scale(1.075);
    margin-left: 13vw;
  }
}

@keyframes bannerUnHoverText {
  from {
    transform: scale(1.075);
    margin-left: 13vw;
  }
  to {
    transform: scale(1);
    margin-left: 10vw;
  }
}

.imageBanner:hover ~ .bannerText {
  position: relative;
  top: -10vw;
  pointer-events:none;
  margin-left: 10vw;
  animation: bannerHoverText 0.5s;
  animation-fill-mode: forwards;
}

.imageBanner:hover ~ .bannerText2 {
  position: relative;
  top: -300px;
  pointer-events:none;
  margin-left: 10vw;
  animation: bannerHoverText 0.5s;
  animation-fill-mode: forwards;
}

.bannerSlider {
  width: 100%;
  position: relative;
}

.bannerSlide {
  height: 30vw !important;
  padding-bottom: 0px !important;
  display: flex;
  flex-direction: column;
}

.bannerSlideUs {
  height: 90vh !important;
  padding-bottom: 0px !important;
}

.bannerText {
  position: relative;
  top: -10vw;
  pointer-events:none;
  margin-left: 10vw;
  animation: bannerUnHoverText 0.5s;
  animation-fill-mode: forwards;
}

.bannerText2 {
  position: relative;
  top: -300px;
  pointer-events:none;
  margin-left: 10vw;
  animation: bannerUnHoverText 0.5s;
  animation-fill-mode: forwards;
}

.bannerTitle {
  font-size: 3vw;
  font-weight: bolder;
  color: white;
  font-family: sans-serif;
  text-shadow: 3px 2px 3px black;
  margin-bottom: 5px !important;
}

.bannerDescription {
  font-size: 2vw;
  font-family: sans-serif;
  color: white;
  text-shadow: 2px 2px 3px black;
}

.bannerNextButton {
  width: 5vw;
  position: relative;
  left: 90vw;
  height: 30vw;
  top: -30vw;
  border: none;
  color: white;
  text-shadow: 2px 2px 2px black;
  background-image: url("../assets/buttonsCarousel.png");
  background-size: cover; 
  background-color: transparent;
  outline:none;
}

.bannerNextButtonUs {
  width: 5vw;
  position: relative;
  height: 90vh;
  border: none;
  color: white;
  text-shadow: -2px 2px 2px black;
  background-image: url("../assets/buttonsCarousel.png");
  background-size: cover; 
  background-color: transparent;
  transform: scaleX(-1);
  opacity: 0.7;
  outline:none;
}

.bannerBackButton {
  width: 5vw;
  position: relative;
  right: 0vw;
  top: -30vw;
  height: 30vw;
  border: none;
  color: white;
  text-shadow: -2px 2px 2px black;
  background-image: url("../assets/buttonsCarousel.png");
  background-size: cover; 
  background-color: transparent;
  transform: scaleX(-1);
  outline:none;
}

.bannerBackButtonUs {
  width: 5vw;
  position: relative;
  height: 90vh;
  border: none;
  color: white;
  text-shadow: 2px 2px 2px black;
  opacity: 0.7;
  background-image: url("../assets/buttonsCarousel.png");
  background-size: cover; 
  background-color: transparent;
  outline:none;
}

.separationLine {
  width: 60%;
  height: 1px;
  background-color: black;
  opacity: 0.1;
  margin-top: 10px;
}

.separationLinePersonCard {
  width: 90%;
  height: 1px;
  background-color: black;
  opacity: 0.1;
  margin-left: 5%;
  margin-right: 5%;
  margin-top: 10px;
}

.separationLineWhole {
  width: 90%;
  height: 2px;
  background-color: black;
  opacity: 0.1;
  margin-top: 100px;
  margin-left: auto;
  margin-right: auto;
}

.news {
  margin-left: auto;
  margin-right: auto;
  padding-top: 40px;
  display: flex;
  flex-direction: column;
  width: 75%;
  align-content: center;
}

.imageTextCard {
  width: 100vw;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  margin-bottom: 2vw;
  margin-top: 2vw;
}

.personCard {
  width: 44vw;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  margin-bottom: 3vw;
  margin-top: 3vw;
  margin-left: 3vw;
  margin-right: 3vw;
  box-shadow: 0px 0px 12px #000000ff;
}

.personCard2 {
  width: 94vw;
  background-color: #ffffff;
  display: flex;
  flex-direction: row;
  margin-bottom: 1vw;
  margin-top: 1vw;
  margin-left: 1.5vw;
  margin-right: 1.5vw;
  box-shadow: 0px 0px 12px #000000ff;
}

.personCard2Mobile {
  width: 94vw;
  background-color: #ffffff;
  display: flex;
  flex-direction: column;
  margin-bottom: 1vw;
  margin-top: 1vw;
  margin-left: 1.5vw;
  margin-right: 1.5vw;
  box-shadow: 0px 0px 12px #000000ff;
  justify-content: center;
}

.newsDetailBackground {
  width: 100vw;
  height: 100vh;
  position: absolute;
  background-color: #55555555;
  z-index: 1000;
  top: 0;
  align-items: center;
  justify-content: center;
  justify-items: center;
}

.newsDetailBox {
  width: 80%;
  height: 85%;
  background-color: rgb(250, 250, 250);
  margin: auto;
  top: 7%;
  position: relative;
  box-shadow: 0px 0px 12px #000000ff;
  overflow: hidden;
}

.newsHeader {
  height: 8%;
  width: 100%;
  padding-left: 2.5vw;
  padding-right: 2.5vw;
  display: flex;
  font-family: sans-serif !important;
  background-color: #ececec;
  justify-content: space-between;
  align-items: center;
  font-weight: bolder;
  font-size: 24px;
  border-bottom: 1px solid #aaa;
}

.newsContent {
  padding-left: 5vw;
  padding-right: 5vw;
  font-size: 18px;
  font-family: sans-serif !important;
  text-align: justify;
}

.newsProperties {
  height: 100%;
  width: 35%;
  background-color: #efefef;
  justify-content: center;
  text-align: center;
}

.newsPropertiesMobile {
  height: 30%;
  width: 100%;
  background-color: #efefef;
  justify-content: center;
  text-align: center;
}

.newsPortraitImage {
  height: 100%;
  width: 100%;
  padding: 5%;
  object-fit: cover;
}

.newsTitle {
  margin-left: 2.5vw;
  margin-right: 2.5vw;
  padding-left: 5px;
  padding-right: 5px;
  padding-top: 20px;
  padding-bottom: 5px;
  font-size: 20px;
  font-weight: bolder;
  font-family: sans-serif !important;
  border-bottom: 1px solid #aaa;
  color: #325eab;
}

.mapContainer {
  width: 40vw;
  height: 65vh;
  margin-left: 5vw;
  margin-right: 5vw;
  margin-bottom: 30px;
}

.mapContainerMobile {
  width: 90vw;
  height: 65vh;
  margin: auto;
}

.marker {
  background-image: url('/img/position.jpg');
  background-size: cover;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  cursor: pointer;
}

.emailForm {
  width: 40vw;
  margin-left: 5vw;
  margin-right: 5vw;
}

.emailFormMobile {
  padding-top: 30px;
  width: 90vw;
  margin: auto;
}